import React from 'react';
import RegistrationForm from './RegistrationForm';

const Register = () => {
  return (
      <RegistrationForm />
  );
};

export default Register;
